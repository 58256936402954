<template>
  <div>
    <div class="router-view-container">
      <div class="flex items-center space-x-4 mb-5">
        <vs-button @click="$router.go(-1)" border size="large">
          <svg-icon icon-class="arrow_left" className="primary"></svg-icon>
        </vs-button>

        <h1 class="page_title">{{ $route.meta.title }}</h1>
      </div>

      <div v-if="is_API_loaded" class="space-y-10">
        <section class="wrap">
          <div class="leftBlock">
            <h3 class="h3 sm:mt-4">自訂運送方式</h3>
            <p>顧客註冊成為新會員時，系統會自動發送購物金。</p>
          </div>
          <div class="rightBlock" style="padding: 16px 0">
            <div class="flex items-center justify-end px-4">
              <router-link :to="{ name: 'new-shipping' }">
                <vs-button transparent color="success" size="mini">
                  <span class="text-sm">新增運送國家/地區</span>
                </vs-button>
              </router-link>
            </div>
            <ul v-if="shippingList.length > 0" class="divide-y-2 divide-gray-background">
              <li class="px-4 py-2">
                <p class="text-gray-400">顯示的運送名稱</p>
              </li>
              <li
                v-for="item in shippingList"
                :key="item.slno"
                class="flex items-center justify-between px-4 py-2 hover:bg-gray-background transition-colors"
              >
                <p>{{ item.title }}</p>
                <router-link
                  :to="{
                    name: 'edit-shipping',
                    params: {
                      id: item.slno,
                    },
                  }"
                  ><vs-button transparent color="success" size="mini">
                    <span class="text-sm">編輯</span>
                  </vs-button></router-link
                >
              </li>
            </ul>
            <p v-else class="text-center text-gray-400">尚無任何自訂運送方式</p>
          </div>
        </section>
        <hr />
        <section class="wrap">
          <div class="leftBlock">
            <h3 class="h3 sm:mt-4">第三方物流串接</h3>
            <p>顧客註冊成為新會員時，系統會自動發送購物金。</p>
          </div>
          <div class="rightBlock">
            <div class="grid grid-cols-2 sm:grid-cols-3 gap-4">
              <router-link
                :to="{ name: item.routerName }"
                v-for="(item, index) in payments_img"
                :key="index"
                class="
                  flex flex-col
                  items-center
                  justify-between
                  p-4
                  border-2
                  rounded-xl
                  border-gray-background
                  shadow-xl
                  hover:shadow-none
                  transition-all
                  duration-300
                  transform
                  hover:translate-y-1
                "
              >
                <div class="flex items-center justify-center flex-grow mb-2">
                  <img :src="item.img" :alt="item.title" />
                </div>
                <p>{{ item.title }}</p>
              </router-link>
            </div>
          </div>
        </section>
      </div>

      <!-- 骨架屏 -->
      <div class="space-y-4" v-else>
        <section v-for="(item, index) in 2" :key="index" class="wrap">
          <div class="leftBlock">
            <skeleton class="mt-4" width="100px" />
            <skeleton width="200px" height="10px" />
            <skeleton width="200px" height="10px" />
            <skeleton width="200px" height="10px" />
          </div>
          <div class="rightBlock">
            <div class="flex items-center justify-between">
              <skeleton width="100px" />
              <skeleton width="100px" />
            </div>
            <div class="flex items-start flex-col space-y-2">
              <skeleton v-for="(item, index) in 3" :key="index" width="200px" />
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'store_payments',
  data() {
    return {
      is_API_loaded: false,
      shippingList: {},
      payments_img: [
        // {
        //   img: require('@/assets/images/payments/ecpay_logo.png'),
        //   title: '自訂收款方式',
        // },
        {
          img: require('@/assets/images/payments/ecpay_logo.png'),
          title: '綠界科技',
          routerName: 'ecpay-shipping',
        },
        {
          img: require('@/assets/images/shipping/ezship_logo.gif'),
          title: 'ezShip 台灣便利配',
          routerName: 'ezship',
        },
      ],
    }
  },
  created() {
    this.loadShippingList()
  },
  methods: {
    // 4.4.2.1 讀取物流(賣家宅配)列表
    loadShippingList() {
      this.$axios({
        url: 'front/set/app/getLogisticsList.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
        },
      }).then((res) => {
        if (res.data.Success) {
          console.log('讀取物流(賣家宅配)列表', res.data.Data)
          this.shippingList = res.data.Data
          this.is_API_loaded = true
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.wrap {
  @apply flex flex-col sm:flex-row sm:space-x-10 space-y-4 sm:space-y-0;
}

.leftBlock {
  @apply w-full sm:w-1/3 space-y-4 flex-shrink-0;
}

.rightBlock {
  @apply flex-grow h-fit bg-white p-4 rounded-xl space-y-4;
}
// .checkbox_section {
//   @apply w-28 flex-shrink-0;
// }

// .title_section {
//   // @apply flex items-center w-min sm:w-full;
//   // .title {
//   //   @apply w-48 sm:flex-grow;
//   // }
//   @apply grid gap-4
//   w-full text-left;
//   grid-template-columns: 100px 150px repeat(3, 100px);
//   @screen md {
//     @apply grid-cols-5;
//   }
// }
</style>
